$base-color: #3d9ba8;
$active-base-color: #40c2cc;

.cf:before,
.cf:after {
    content: " ";
    display: table; 
}

.cf:after {
    clear: both;
}

.muted-bg {
	background: #f2f2f2;
}

.text-center {
	text-align: center;
}

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
    40% {transform: translateY(-30px);}
    60% {transform: translateY(-15px);}
} 

html, body {
	margin: 0;
	padding: 0;
	font-family: Prelo, Helvetica Neue, Helvetica, sans-serif;
	font-size: 16px;
}


a {
	color: $base-color;

	&:hover {
		color: #737373;
	}

	&:active {
		color: $active-base-color;
	}

	&:focus {
		color: #737373;
	}
}

blockquote {
	border-left: 5px solid $base-color;
}



.icon-large {
	width: 110px;
	height: 110px;
	border: 3px solid $base-color;
	border-radius: 50%;
	text-align: center;


	i {
		font-size: 72px;
		line-height: 104px;
	}
}

header {
	width: 100%;
	height: 100vh;
	background: url("../images/wave.jpg");
	background-size: cover;
	background-position: center center;
	background-attachment: fixed;
	position: relative;
	min-height: 768px;

	@media (max-height: 767px) and (max-width: 767px) {
		

	}

	.overlay {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom:0;
		background: rgba(0,0,0,0.65);
	}

	.header-main-content {
		position: absolute;
		top: 20vh;
		left: 50%;
		transform: translateX(-50%);
		width: 100%;
		max-width: 920px;
		padding: 0 15px;

		@media (max-height: 767px) and (max-width: 767px) {
			top: 8vh;	
		}

		@media (max-height: 767px) and (max-width: 320px) {
			top: 4vh;	
		}

		.gstream-logo-header {
			float: left;
			margin-right: 36px;


			@media (max-height: 767px) and (max-width: 767px) {
				width: 100%;
				float: none;
				text-align: center;
				margin-right: 0;	
			}

			@media (max-height: 1024px) and (max-width: 767px) {
				width: 100%;
				float: none;
				text-align: center;
				margin-right: 0;	
			}


			img {
				width: 140px;
				height: auto;
				display: inline-block;

				@media (max-height: 767px) and (max-width: 767px) {
					width: 56px;	
				}
			}
		}

		.text {
			overflow: hidden;
			color: #fff;

			@media (max-height: 767px) and (max-width: 767px) {
				text-align: center;
				
			}

			

			.title-text {
				font-size: 36px;
				font-weight: 200;
				text-transform: uppercase;
				@media (max-height: 1024px) and (max-width: 767px) {
					margin-top: 36px;	
				}

				@media (max-height: 767px) and (max-width: 320px) {
					margin-top: 36px;	
				}

				@media (max-height: 767px) and (max-width: 767px) {
					font-size: 24px;	
				}

				span {
					font-weight: 600;
				}
			}

			.title-description {
				font-size: 59px;
				font-weight: 900;
				text-transform: uppercase;
				line-height: 70px;

				@media (max-height: 1024px) and (max-width: 767px) {
					margin-top: 12px;	
				}

				@media (max-height: 767px) and (max-width: 767px) {
					font-size: 36px;
					line-height: 38px;
					margin-top: 24px;
				}

				@media (max-height: 767px) and (max-width: 320px) {
					margin-top: 6px;
					

				}
			}
		}
	}

	.social-networks {
		text-align: center;
		margin-top: 50px;

		a {
			transition: opacity 300ms;
			margin: 0 10px;
			opacity: 0.4;

			&:hover {
				opacity: 1;
			}

			img {
				width: 32px;
			}
		}
	}

	.wrapper {
		text-align: center;
	}

	.scroll-down {
		margin-top: 70vh;
		animation-duration: 2s;
		animation-name: bounce;
		animation-fill-mode: both;
		animation-iteration-count: infinite;
		outline: none;
		display: inline-block;

		img {
			width: 44px;

		}
	}
}

.navigation {
	height: 74px;
	background: #f2f2f2;
	
	box-shadow: 0px 0px 26px 0px rgba(0,0,0,0.3);
	text-align: center;
	position: relative;
	z-index: 2;

	
	@media (max-width: 767px) {
		height: 56px;
	}

	#navigation-links {
		margin: 0;
		padding: 0;

		@media (max-width: 767px) {
			display: none;
			position: absolute;
		}

		li {
			display: inline;
			padding: 0 20px;

			a {
				text-decoration: none;
				font-size: 22px;
				line-height: 74px;
				font-weight: 300;


				&.current {
					color: #737373;
				}
			}
		}

		@media (max-width: 767px) {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			display: none;

			li {
				display: block;
				width: 100%;
				margin: 0;
				padding: 0;

				a {
					display: block;
					line-height: auto;
					padding: 25 0;
					width: 100%;
					background: rgba(0,0,0,0.8);
					color: #fff;
					border-top: 1px solid rgba(255,255,255,0.1);

					&.current {
						font-weight: bold;
						color: #fff;
					}
				}
			}
			
		}

		
	}

	@media (max-width: 767px) {
		.nav-wrapper {
			position: fixed;
			left: 0;
			right: 0;
			top: 56px;
			bottom: 0;
			overflow-y: scroll;
			display: none;
		}
	}

	.navigation-toggle {
		position: absolute;
		top: 13px;
		left: 0;

		img {
			width: 28px;
			height: auto;
		}

		@media (max-width: 767px) {
			display: block;
		}

		@media (min-width: 768px) {
			display: none;
		}
	}
}






.nav-fixed {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	z-index: 2;
}

section {
	padding: 100px 0;


	.section-heading {
		text-align: center;

		.section-title {
			margin-top: 0;
			margin-bottom: 0;
			font-size: 46px;
			font-weight: 300;
			color: #595959;

		}

		.bar {
			width: 200px;
			height: 3px;
			background: $base-color;
			display: inline-block;
			margin-top: 22px;
			font-weight: 300;
		}

		.section-subtitle {
			margin-top: 35px;
			color: $base-color;
			font-weight: 300;
			font-size: 22px;
			line-height: 33px;
		}
	}

	.section-content {
		margin-top: 50px;
	}

	&:last-of-type {
		padding-bottom: 0;
	}
}

section#services {
	.service {
		text-align: center;

		@media (max-width: 991px) {
			margin-top: 50px;

			&:first-child {
				margin-top: 0;
			}
		}

		.icon-large {
			display: inline-block;
		}

		.service-title {
			margin-top: 28px;
			font-size: 24px;
			font-weight: bold;
			color: $base-color;
		}

		.service-description {
			margin-top: 20px;
		}
	}
}

section#about {
	.about-us-signage {
		text-align: center;
		margin-top: 20px;
		img {
			display: inline-block;
			width: 70px;
			height: auto;
		}
	}
}

section#process {
	.phase {
		.phase-title {
			font-size: 24px;
			font-weight: bold;
			color: $base-color;
		}

		.icon-large {
			margin-top: 24px;
			display: inline-block;
		}

		.phase-description {
			margin-top: 24px;
		}

		@media (max-width: 991px) {
			margin-top: 50px;

			&:first-child {
				margin-top: 0;
			}
		}

		@media (max-width: 767px) {
			text-align: center;
		}
	}

	.second-row {
		margin-top: 50px;
	}
}

section#partners {
	.partner {
		
		opacity: 0.5;
		transition: opacity 300ms;
		position: relative;

		&:hover {
			opacity: 1;
		}

		img {
			text-align: center;
			max-height: 80px;
			display: inline-block;
			line-height: 80px;
		}

		@media (max-width: 991px) {
			margin-top: 70px;

			&:first-child {
				margin-top: 0;
			}
		}
	}
}

section#contact {
	#response {
		text-align: center;

		span {
			display: inline-block;
			margin-bottom: 40px;
			color: $base-color;
			font-size: 22px;
			display: none;
		}
	}

	form {
		input {
			width: 100%;
			height: 65px;
			border: 2px solid #cccccc;
			padding-left: 27px;
			font-weight: 600;
			outline: none;

			&:active {
				border-color: $base-color;
			}

			&:focus {
				border-color: $base-color;
			}

			&::-webkit-input-placeholder {
				color: $base-color;
				
				font-weight: 600;
				font-size: 22px;
			}

			&::-moz-placeholder {
				color: $base-color;
				
				font-weight: 600;
				font-size: 22px;
			}

			&:-ms-input-placeholder {
				color: $base-color;
				
				font-weight: 600;
				font-size: 22px;
			}

			@media (max-width: 991px) {
				margin-top: 30px !important;

				&:first-child {
					margin-top: 0;
				}
			}

		}

		
		textarea {
			width: 100%;
			border: 2px solid #cccccc;
			padding: 27px;
			font-weight: 600;
			margin-top: 30px;
			outline: none;

			&:active {
				border-color: $base-color;
			}

			&:focus {
				border-color: $base-color;
			}

			&::-webkit-input-placeholder {
				color: $base-color;
				
				font-weight: 600;
				font-size: 22px;
			}

			&::-moz-placeholder {
				color: $base-color;
				
				font-weight: 600;
				font-size: 22px;
			}

			&:-ms-input-placeholder {
				color: $base-color;
				
				font-weight: 600;
				font-size: 22px;
			}
		}

		.submit-button {
			width: 328px;
			height: 65px;
			border: 1px solid $base-color;
			background: $base-color;
			border-radius: 3px;
			color: #fff;
			text-transform: uppercase;
			font-weight: 600;
			font-size: 22px;
			float: none;
			display: inline-block;
			margin-top: 54px;
			transition: opacity 300ms;
			padding-left: 0;
			padding-right: 0;

			&:hover {
				opacity: 0.8;
			}
		}
	}

	.info-map {
		.map {
			height: 600px;
			width: 100%;
			margin-top: 80px;
		}

		.info {
			background: rgba(61,155,168,0.85);
			color: #fff;
			text-align: center;
			padding: 25px;
			position: relative;
			font-size: 22px;
			font-weight: 300;
			text-transform: uppercase;
			top: 250px;
			z-index: 1;
		}
	}
	
}

footer {
	height: 120px;

	.copyright {
		float: left;

		line-height: 120px;

		.logo {
			display: inline-block;

			img {
				width: 77px;
				height: auto;
			}
		}

		span {
			color: $base-color;
			margin-left: 12px;
		}
	}

	.social-networks {
		float: right;
		line-height: 120px;


		div {
			display: inline-block;
			a {
				margin-left: 20px;

				img {
					width: 32px;
					height: 32px;
				}

				&:hover {
					opacity: 0.8;
				}
			}
		}

		@media (max-width: 767px) {
			margin-bottom: 40px;
			margin-top: 25px;
			float: left;
			clear: both;
			line-height: 32px;

			div {
				a {
					margin-right: 20px;
					margin-left: 0;

				}
			}
		}
		
	}
}